// eslint-disable-next-line import/prefer-default-export
export const obj = {
  title: "The right parts to grow your business",
  subtext:
    "Attract new customers, increase sales, and get paid faster with easy-to-use software built for the auto parts industry.",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/demo",
  },
};
export const simpleton = {
  title: "The right parts to grow your business",
  subtext:
    "Attract new customers, increase sales, and get paid faster with easy-to-use software built for the auto parts industry.",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/demo",
  },
  list: ["hello", "world", "its", "me", "carlos"],
};
export const homeHero = {
  title: "Point-of-sale systems to fit your business.",
  subtext: "\nFrom a partner who listens",
  heroImg: "hero-mobile-v3.png",
  imageClassName: "fixed-height-600 top-adjustement",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/demo",
  },
  SecondaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/demo",
  },
  video: {
    videoBtn: "Watch video",
    posterWrapperClass: "",
    posterImg: "Home_video_thumb.png",
    videoUrlId: "8QVvWcGplAw",
  },
};
export const businessTypesCar = {
  title: "The right tool for the right job",
  subtext:
    "Your customers expect top-notch service from you, so why expect anything less with your merchant services? We’ll always put in the extra work to make sure you have the right tools to succeed with every new challenge.",
  slides: [
    {
      bg: "restaurant.png",
      cardTitle: "Restaurants",
      cardContent: "Fine Dining, Casual Dining, QSR, Food Stores, Food Trucks",
      cardIcon: "cart.svg",
      icon: "restaurant.png",
      linkTo: "/restaurant-pos",
    },
    {
      bg: "auto.png",
      cardTitle: "Automotive",
      cardContent:
        "Oil Change Shops, Auto Parts, Auto Body, Repair, Tire Shops, Tint Shops",
      icon: "automotive.png",
      linkTo: "/automotive",
    },
    {
      bg: "beauty.png",
      cardTitle: "Health & beauty",
      cardContent:
        "Nail/Hair Salons, Tanning Salons, Barbers, Dentists, Veterinarian",
      icon: "health-and-beauty.png",
      linkTo: "/health-and-beauty",
    },
    {
      bg: "professional.png",
      cardTitle: "Professional Services",
      cardContent:
        "Lawyer, Accountant, Tow Truck, Architect, Plumber, Appraiser",
      icon: "prof-services.png",
      linkTo: "/professional-services",
    },
  ],
};

export const heroSSI = {
  title: "Custom tech built around your needs",
  subtext: `We listen and learn so we can recommend the technology that makes sense specifically for you. Let's get started.`,
  bgColor: "#F3F4F6",
  ctaInfo: {
    ctaTitle: "See how we can help you",
    ctaTarget: "/demo",
  },
};

export const heroTestimonials = {
  title: "What other restaurant owners are saying",
  reviews: [
    {
      quote:
        "From the initial consultation, to ordering, to implementation and integration, the entire process of on-boarding the SpotOn POS System has been seamless and has exceeded our expectations.",
      reviewDate: "Mar 9, 2021",
      reviewer: "Pete M.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn continues to be an invaluable technology partner that listens well and proactively presents innovative solutions to drive our business forward.",
      reviewDate: "Feb 9, 2021",
      reviewer: "Ben P.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn has given me the data access I need to manage my business from a macro as well as micro perspective. The software is easy to access and utilize.",
      reviewDate: "Dec 5, 2020",
      reviewer: "Pamela H.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "Every issue and question has been answered or addressed within hours, if not minutes.I can't say enough good about this company and software.",
      reviewDate: "Feb 24, 2021",
      reviewer: "Brandy R.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "From back office, digital marketing, built our website to take orders off of. The programming is great to allow you to do more tasks with better trained staff.",
      reviewDate: "Dec 1, 2020",
      reviewer: "Marcus O.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
  ],
};

export const heroLargeCta = {
  title: "Book your consultation.",
  subtext:
    "Learn about everything that you may need working with different SpotOn products.",
  ctaImg: "cta-people.png",
  ctaInfo: {
    ctaTitle: "Learn more",
    ctaTarget: "/demo",
  },
};
