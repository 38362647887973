import React, { useEffect, useState, useRef } from "react";
import { string, object, bool } from "prop-types";
import { isMobileOnly } from "react-device-detect";
import VideoHeroStyles from "./styles";
import useGetViewportY from "../../hooks/use-get-viewport-y";
import PrimaryCta from "../../components/Buttons/primary-cta";
// import SecondaryCta from '../../components/Buttons/secondary-cta';
import HeroImages from "../../components/ImageQuerys/HeroImages";
import useWindowSize from "../../hooks/use-window-size";
import PowerPartnerBanner, {
  PowerPartnerBannerMobile,
} from "../PowerPartnerBanner";

const VideoHero = (props) => {
  const { sectionData, video, withBanner } = props;
  const [isPortrait, setIsPortrait] = useState(false);
  const [isMobile, setIsMobile] = useState(true);
  const [mounted, setIsMounted] = useState(false);
  const viewportY = useGetViewportY();
  const { width, height } = useWindowSize();
  const videoRef = useRef();

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    setIsPortrait(width <= height);
  }, [width, height]);

  useEffect(() => {
    setIsMobile(isMobileOnly);
  }, [isMobileOnly]);

  const setPlayBack = () => {
    videoRef.current.playbackRate = 0.8;
  };

  return (
    <section className="v-hero relative">
      {mounted && isMobile ? (
        <HeroImages imageName={sectionData.heroImg} className="v-hero__image" />
      ) : null}

      {withBanner && (
        <>
          <PowerPartnerBannerMobile className="z-1 md:hidden absolute bottom-8 left-0 right-0" />
          <PowerPartnerBanner className="z-1 hidden md:block absolute top-40 lg:top-60 right-0" />
        </>
      )}

      {!isMobile && (
        <div id="videoBGWrapper" className="">
          <video
            ref={videoRef}
            id="videoBG"
            autoPlay="autoplay"
            onCanPlay={() => setPlayBack()}
            muted
            loop="loop"
            // poster={`../../images/hero-images/${poster}`}
            className="v-hero__video"
            style={{ left: isPortrait ? "-90%" : 0 }}
          >
            <source src={video} type="video/mp4" />
          </video>
        </div>
      )}

      <div className="v-hero__titles-wrapper">
        <h1 className="v-hero__main-title whitespace-pre-wrap lg:whitespace-pre-line">
          {sectionData.title}
          <span className="v-hero__sub-title">
            {sectionData.subtext}
            <span className="blue_dot">.</span>
          </span>
        </h1>
      </div>

      <div className="v-hero__ctas">
        {sectionData?.PrimaryCta && (
          <PrimaryCta
            ctaTitle={sectionData.PrimaryCta.ctaTitle}
            target={sectionData.PrimaryCta.ctaTarget}
            style={{ marginLeft: 15 }}
          />
        )}

        {/* {sectionData?.SecondaryCta && ( */}
        {/*  <SecondaryCta */}
        {/*    ctaTitle={sectionData.SecondaryCta.ctaTitle} */}
        {/*    target={sectionData.SecondaryCta.ctaTarget} */}
        {/*    withWhiteChevron */}
        {/*  /> */}
        {/* )} */}
      </div>

      <VideoHeroStyles />
      <style jsx>{`
        #videoBG {
          top: -${Math.abs(viewportY)}px;
        }
      `}</style>
    </section>
  );
};

VideoHero.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  sectionData: object.isRequired,
  video: string.isRequired,
  withBanner: bool,
};

VideoHero.defaultProps = {
  withBanner: false,
};

export default VideoHero;
