// eslint-disable-next-line import/prefer-default-export
export const businessTypes = {
  title: `Flexible, easy-to-use technology built for your business`,
  cards: [
    {
      bg: "restaurants.png",
      cardTitle: "Restaurant",
      cardContent: "Feed your guests.\n Fuel your dreams.",
      linkTo: "/restaurant-pos",
    },
    {
      bg: "retail.png",
      cardTitle: "Retail",
      cardContent: "Sell in-store and online, effortlessly.",
      linkTo: "/retail",
    },
    {
      bg: "enterprise.png",
      cardTitle: "Enterprise",
      cardContent: "Deliver exceptional guest experiences, at scale.",
      linkTo: "/enterprise",
    },
  ],
};
