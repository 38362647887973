// eslint-disable-next-line import/prefer-default-export
export const rotarySection = {
  title: "Helping businesses through thick and thin",
  subtext:
    "Running a business means wearing a lot of hats. Too often, it can feel like you have to do everything on your own. It shouldn’t be that way. That’s why we provide local service and 24/7/365 personal support—so you always have a trusted team of small business experts in your corner.",

  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/",
  },
  // images: [],
  images: [
    {
      name: "Kiwaski Vaughn",
      location: "Pieces By Vaughn",
      image: "1-image.png",
      className: "image-wrapper--1",
      thumbnail: "1-thumbnail.png",
    },
    {
      name: "Mark Silver",
      location: "Argenti Designer Jewelers",
      image: "2-image.png",
      className: "image-wrapper--2",
      thumbnail: "2-thumbnail.png",
    },
    {
      name: "Derek Griffith",
      location: "Northeast Auto Imports",
      image: "3-image.png",
      className: "image-wrapper--3",
      thumbnail: "3-thumbnail.png",
    },
    {
      name: "Lizzy Sycamore",
      location: "Lizzy’s Pink Boutique",
      image: "4-image.png",
      className: "image-wrapper--4",
      thumbnail: "4-thumbnail.png",
    },
    {
      name: "Adam Snyder",
      location: "The Brixton",
      image: "5-image.png",
      className: "image-wrapper--5",
      thumbnail: "5-thumbnail.png",
    },
    {
      name: "Amir Sadahiani",
      image: "6-image.png",
      className: "image-wrapper--6",
      thumbnail: "6-thumbnail.png",
      location: "Activmeals",
    },
  ],
};

// eslint-disable-next-line import/prefer-default-export
export const rotarySectionV2 = {
  title: "Helping businesses through thick and thin",
  subtext: `We built SpotOn to empower business owners like you to run your business more efficiently, reach more customers, and realize your dreams. Scroll to read the stories of these incredible entrepreneurs that we are proud to call our clients.`,
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/",
  },
  data: [
    {
      businessName: "Double Zero",
      businessType: "Restaurant",
      thumbnail: "double-zero-thumb.png",
      // image: 'loma-brewing.png',
      videoId: "nM9F-g6o3CY",
      location: "6 location",
      owner: "Matthew Kenney",
      website: "www.matthewkenneycuisine.com/hospitality",
      paragraphs: [
        `With 45 restaurants that run the gamut from fast casual to food halls to fine dining, CEO of Matthew Kenney Cuisine and vegan chef Matthew Kenney doesn’t have the time—or resources—to train staff on 45 different POS systems or sort through 45 different types of data. To keep his business running smoothly and efficiently, he needs a single solution that helps employees speak the same language across all of his restaurants, including at his six Double Zero locations.`,
        `SpotOn Restaurant gives Kenney a single, unified system to give him insights into every aspect of his business—day by day, hour by hour. Having consistency in resources and reporting across business areas enables Kenney to make smarter decisions, quicker. Combined with online ordering from SpotOn, Double Zero has seen a 30 – 40% increase in weekly revenue over the previous year.`,
      ],
    },
    {
      businessName: "Loma Brewing",
      businessType: "Bar & Nightclub",
      thumbnail: "loma-brewing-thumb.png",
      // image: 'loma-brewing.png',
      videoId: "JFBJAF9KGTs",
      location: "Los Gatos, CA",
      owner: "Kevin Youkilis",
      website: "www.lomabrew.com",
      paragraphs: [
        `With fewer servers on hand, and customers looking to reduce the amount of things their hands touch, former baseball player and owner of Loma Brewing, Kevin Youkilis had to figure out how to do more with less at his popular brew pub.`,
        `By introducing SpotOn’s QR codes to their ordering process, which enables customers to order and pay directly from their phone, staff are spending less time running from front-of-house to back-of-house, guests are spending more time enjoying their beer and each other’s company, and Youkilis and his staff are spending more time discovering craft beers to add to their menu.`,
      ],
    },
    {
      businessName: "Northeast Auto Imports",
      businessType: "Automotive",
      thumbnail: "northeast-thumb.png",
      // image: 'loma-brewing.png',
      videoId: "4pIhYB_LvJM",
      location: "Hudson, NH",
      owner: "Derek Griffith",
      website: "www.northeastautoimports.com",
      paragraphs: [
        `Professional race car driver and Northeast Auto Imports owner Derek Griffith had to figure out how to be in two places at one time. In addition to running 8 races in 9 days, including at Daytona International Speedway, Derek is also running a successful auto import business more than 1,200 miles away.`,
        `Derek leverages SpotOn technology to stay connected and keep his business running, even when he’s running races. Derek always knows what’s happening at his Japanese domestic import dealership in real-time by monitoring sales remotely and keeping tabs on his online reputation. And with online appointment booking, customers can schedule test drives anytime that makes sense for them.`,
      ],
    },
    {
      businessName: "Temaki Den",
      businessType: "Restaurant",
      thumbnail: "temaki-den-thumb.png",
      image: "temaki-den.png",
      location: "Denver, CO",
      owner: "Kenta Kamo",
      website: "www.temakiden.com",
      paragraphs: [
        `Kenta Kamo and co-owner Chef Toshi Kizaki had a proven track record of running successful restaurants. Still, they faced a unique challenge with Temaki Den—they were launching a brand new restaurant in the middle of a pandemic. With indoor dining prohibited, they needed a cost-effective and efficient solution for delivery.`,
        `With SpotOn, they got exactly what they needed with SpotOn Order, an integrated online ordering solution that lets their guests choose between pickup and delivery right from their website, with orders going directly to their restaurant point-of-sale. In their first 3 months after opening, 100% of Temaki Den’s sales came through SpotOn Order, with a 20% average savings on each order compared to using third party ordering apps.`,
      ],
    },
  ],
};
