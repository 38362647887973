import React from "react";
import { colors, viewports } from "../../style-vars";

const VideoHeroStyles = () => (
  <style jsx global>{`
    .v-hero {
      position: relative;
      min-width: 100%;
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .v-hero__video {
      display: none;
    }

    .v-hero__image {
      width: 100%;
      height: 100vh;
      //object-fit: cover;
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
    }

    //Hack for iOS15 - it does not play <video> for some reason, known bug across the community

    //#videoBG {
    //  min-width: 100vw;
    //  min-height: 100vh;
    //  position: fixed;
    //  //position: absolute;
    //  left: 0;
    //  bottom: 0;
    //}

    #videoBGWrapper {
      min-width: 100vw;
      min-height: 100vh;
      position: fixed;
      //position: absolute;
      left: 0;
      bottom: 0;
    }

    #videoBG {
      position: absolute;
      min-width: 100vw;
      min-height: 100vh;
    }

    // End of hack

    .v-hero__titles-wrapper {
      padding: 0 16px;
      //position: absolute;
      //top: 45%;
      //left: 0;
      position: relative;
    }

    .v-hero__ctas {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin: 40px auto;
    }

    .v-hero__ctas .cta-primary,
    .v-hero__ctas .cta-secondary {
      margin: 0 10px !important;
    }

    @media ${viewports.smOrBigger} {
      .v-hero__titles-wrapper {
        padding: 0 36px;
      }

      .v-hero__ctas {
        margin-top: 30px;
      }
    }
    @media ${viewports.mdOrBigger} {
      .v-hero {
        justify-content: center;
      }

      .v-hero__video {
        display: block;
      }

      .v-hero__titles-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-top: 80px;
      }

      //.v-hero__titles-wrapper,
      //.v-hero__ctas {
      //  position: relative;
      //}
    }
    @media ${viewports.lgOrBigger} {
      .v-hero__titles-wrapper {
        display: flex;
        flex-direction: column;
        text-align: center;
      }

      .v-hero__image {
        //display: none;
      }
    }

    .v-hero__main-title,
    .v-hero__sub-title {
      font-size: 42px;
      color: ${colors.white};
      line-height: 50px;
      //text-align: center;
    }
    .v-hero__main-title {
      //margin: -50px 0 20px 0;
    }
    .v-hero__sub-title {
      font-weight: 700;
      margin-bottom: 40px;
      margin-left: 10px;
    }
    @media ${viewports.mdOrBigger} {
      .v-hero__sub-title {
        margin-left: 0;
      }
    }
    @media ${viewports.lgOrBigger} {
      .v-hero__main-title,
      .v-hero__sub-title {
        font-size: 68px;
        line-height: 75px;
      }
    }

    .v-hero__ctas {
      display: flex;
      align-items: center;
      //margin-top: 40px;
    }

    .v-hero .cta-secondary {
      color: ${colors.white};
    }
  `}</style>
);

export default VideoHeroStyles;
