import React from "react";
import loadable from "@loadable/component";
// import Carousel from "nuka-carousel";
import Layout from "../components/Layout/layout";
import SEO from "../components/seo";
import { homeHero, heroSSI } from "../data/hero-data";
import { whyUsOverview } from "../data/why-data";
import { businessTypes } from "../data/business-types-data";
import { restaurantArticles } from "../data/restaurant-data";
import { solutionsSection, solutionsSlides } from "../data/carousel_data";
import { rotarySectionV2 } from "../data/rotary-section-data";
import { viewports } from "../style-vars";

import ogImage from "../images/global_assets/og-image.png";
import heroVideo from "../videos/index-hero_v2.mp4";

import VideoHero from "../components-v2/VideoHero";
// import Section from "../components-v2/Base/Section";

const WhyUsOverview = loadable(() => import("../components-v2/WhyUsOverview"));
const BusinessTypes = loadable(() => import("../components-v2/BusinessTypes"));
const SSI = loadable(() => import("../components-v2/SSI"));
const ProductLines = loadable(() => import("../components-v2/ProductLines"));
const PartnerLogos = loadable(() =>
  import("../components-v2/Sections/PartnerLogos")
);
const RotarySection = loadable(() => import("../components-v2/RotarySection"));
const Articles = loadable(() => import("../components-v2/Articles"));
// const CapterraBanner = loadable(() =>
//   import("../components-v2/CapterraBanner")
// );

const IndexPage = () => (
  <Layout
    transparentHeader
    normalBanner
    relMenuHeight={68}
    className="non-fixed-width home"
    customBannerData={{
      headline:
        "💰Online Ordering with No Fees, No Commissions. Switch now for 5 Months Free",
      btnText: "Learn more...",
      btnTarget: "/lp/onlineordering",
      punctuation: ".",
      shouldNotShow: [""],
    }}
  >
    <SEO
      title="POS Systems | Point of Sale Systems For Businesses | SpotOn"
      description="SpotOn's POS Systems are designed to fit the needs of your Restaurant, Retail, or Enterprise business. One-stop POS platform to grow & scale your business."
      image={`https://spoton.com/${ogImage}`}
      orgSchema
    />

    <VideoHero sectionData={homeHero} video={heroVideo} />

    {/* <Section
      animations={false}
      // className="mt-16 lg:mt-20 mb-20 lg:mb-40 2xl:mt-[20vh] home-capterra-carousel"
      className="mt-16 lg:mt-24 xl:mt-32 mb-10 lg:mb-10 home-capterra-carousel"
    >
      <Carousel autoplay autoplayInterval={3000} wrapAround>
        <CapterraBanner
          imageName="capterra-pos.png"
          title={`The <span class="text-primary">top-rated POS</span> company according to actual user reviews<span class="text-primary">.</span>`}
          withCta
          ctaLabel="See the results"
          ctaTarget="/restaurant-pos/capterra-competive-comparison/"
        />

        <CapterraBanner
          imageName="capterra-restaurant-pos.png"
          title={`<span class="text-primary">The #1</span> restaurant POS system according to user reviews<span class="text-primary">.</span>`}
          subtitle="Rated the best in every category by restaurant operators."
          withCta
          ctaLabel="See the results"
          ctaTarget="https://datainsights.capterra.com/p/restaurant-pos/226928/spoton/references?g=na&c=&c=&c=&sc=6321593"
        />

        <CapterraBanner
          imageName="capterra-retail-pos.png"
          title={`Rated the <span class="text-primary">best</span> retail POS by real users<span class="text-primary">.</span>`}
          withCta
          ctaLabel="See the results"
          ctaTarget="https://datainsights.capterra.com/p/retail-pos-system/226928/spoton/references?g=na&c=&c=&c=&sc=6322276"
        />

        <CapterraBanner
          imageName="capterra-smb-pos.png"
          title={`Rated the <span class="text-primary">best</span> point-of-sale for small businesses<span class="text-primary">.</span>`}
          withCta
          ctaLabel="See the results"
          ctaTarget="https://datainsights.softwareadvice.com/small-business-pos-systems/spoton-restaurant/references?c=&c=&c=&sc=6319897"
        />

        <CapterraBanner
          imageName="capterra-pos.png"
          title={`Rated the <span class="text-primary">best</span> POS system based on real user reviews<span class="text-primary">.</span>`}
          withCta
          ctaLabel="See the results"
          ctaTarget="https://datainsights.capterra.com/p/point-of-sale/226928/spoton/references?c=&c=&c=&sc=6320065"
        />
      </Carousel>
    </Section> */}

    {/* <CapterraBanner */}
    {/*  className="mt-16 lg:mt-20 mb-20 lg:mb-40 2xl:mt-[20vh]" */}
    {/*  imageName="capterra-pos.png" */}
    {/*  title={`The <span class="text-primary">top-rated POS</span> company according to actual user reviews<span class="text-primary">.</span>`} */}
    {/* /> */}

    <WhyUsOverview
      // className="md:-mt-10 lg:-mt-28"
      className="mt-10 md:mt-20"
      sectionData={whyUsOverview}
      animations
    />

    <BusinessTypes sectionData={businessTypes} animations />

    <SSI sectionData={heroSSI} animations />
    <ProductLines
      sectionData={solutionsSection}
      productSlides={solutionsSlides}
      animations
      style={{ marginBottom: 75, marginTop: 40 }}
    />

    <PartnerLogos animations />

    <RotarySection sectionData={rotarySectionV2} animations />

    <Articles
      sectionData={restaurantArticles}
      blogFilters={{
        limit: 3,
        include: "tags,authors",
        filter: "tag:small-business",
      }}
      cornerStone={false}
      animations
    />

    <style jsx global>{`
      .home-capterra-carousel .slider-control-centerleft,
      .home-capterra-carousel .slider-control-centerright {
        display: none;
      }
      .content-wrapper {
        margin-top: 0 !important;
      }

      .home .products {
        margin-bottom: -60px;
      }

      @media ${viewports.mdOrBigger} {
        .home .products {
          margin-bottom: -40px;
        }
      }

      @media ${viewports.lgOrBigger} {
        .home .products {
          margin-top: 144px;
          margin-bottom: 70px;
        }

        .home .articles-v2 {
          margin-top: 90px;
        }
      }

      @media ${viewports.xlOrBigger} {
        .home .articles-v2 {
          margin-top: 190px;
        }
      }
    `}</style>
  </Layout>
);

export default IndexPage;
