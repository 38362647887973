// eslint-disable-next-line import/prefer-default-export
export const whyHero = {
  title: "Because business\n is personal",
  subtext:
    "Businesses and local restaurants are the heartbeat of our communities—run by our neighbors, our colleagues, our friends. We built SpotOn to help your business compete and win by pairing innovative software and payment solutions with concierge-style service.",
  img: "why-main-hero.png",
  imgMobile: "why-main-hero-mobile.png",
  imageClassName: "fixed-width fixed-height top-adjustement",
  PrimaryCta: {
    ctaTitle: "Get in touch",
    ctaTarget: "/demo",
  },
};
export const whyFullVideo = {
  title: "Inspired by \n Heart & Hustle",
  subtext:
    "You built your business on hard work, integrity, and treating customers with respect. You deserve a partner who shares those values. We built SpotOn to empower business owners like you to run your business more efficiently, reach more customers, and realize your dreams. Whether you run a cafe, clothing boutique, or a concert venue, we've got you covered.",
  videoURL: "8QVvWcGplAw",
  videoPoster: "why-video-poster.png",
};
export const whyLargeData = {
  sectionTitle: "",
  sectionBlocks: [
    {
      blockTitle: "A relationship, not a contract",
      blockSubTitle:
        "When you work with SpotOn, you’re entering into a relationship - not a contract - built on the simple truth that shared respect drives shared success. That means our local account executives are by your side from the start, supported by an industry-leading team of experts available 24/7. When you call, we answer. When you need us, we show up.",
      blockImg: "new-why-a.png",
      // ctaPrime: {
      //   ctaTitle: 'Chat with our team',
      //   ctaTarget: '/demo',
      // },
      left: 10,
      right: 14,
      styles: {
        width: "80%",
        marginLeft: "20%",
      },
    },
    {
      blockTitle: "Integrated, intuitive & \n available everywhere",
      blockSubTitle:
        "Advanced doesn’t have to mean complex. We believe the best technology is so simple and so intuitive that you barely notice it's there. That’s why our technology is built to blend in with the way you do business. And everything you need - from sales reports to staff scheduling, email campaigns to inventory - is accessible from a single, integrated dashboard that’s available in-store, online, or anywhere your business takes you.",
      blockImg: "new-why-b.png",
      left: 16,
      right: 8,
      styles: {
        width: "80%",
      },
    },
  ],
};

export const whyBigBlock = {
  introTitle: "Helping you compete & win",
  introSub:
    "You got into this because you had the guts to build something -- something meaningful, something good. We got into this because we see small businesses as the heart and soul of our communities, and we believe you deserve better than iron-clad contracts, intolerable wait times, and one-size-fits-all solutions. You deserve technology that is tailored to meet your specific needs and a partner to help you grow -- to go places you didn’t think you could, to expand faster than you ever anticipated. We’re ready to help you get there.",
  content: [
    "content-a.png",
    "content-b.png",
    "content-c.png",
    "content-d.png",
    "content-e.png",
    "content-a.png",
    "content-b.png",
    "content-c.png",
    "content-d.png",
    "content-e.png",
  ],
};

export const whyProducts = {
  introTitle: "Built for your needs",
  introSub:
    "We don’t believe in one-size-fits-all. We listen and learn so we can recommend the technology that makes sense specifically for you. Whether you need simple mobile payment technology or a fully integrated restaurant management system, we match solutions to meet your needs.",
  link: "/restaurant-pos",
  products: [
    {
      img: "terminal.png",
      area: "a",
      popData: {
        name: "SpotOn Sidekick",
        description:
          "SpotOn Sidekick makes it easier than ever for mobile kitchens and on-the-go food businesses to take orders, print tickets and process payments.",
        link: "/products/sidekick",
      },
    },
    {
      img: "reserve.png",
      area: "b",
      popData: {
        name: "SpotOn Reserve",
        description:
          "Create a VIP guest experience while booking more reservations, turning tables faster, and increasing revenue with SpotOn Reserve.",
        link: "/products/reserve",
      },
    },
    {
      img: "register.png",
      area: "c",

      popData: {
        name: "SpotOn Register",
        description:
          "Boost efficiency and connect with customers like never before to accelerate revenue growth with SpotOn Register.",
        link: "/products/register",
      },
    },
    {
      img: "device.png",
      area: "d",
      popData: {
        name: "SpotOn Terminal (POS)",
        description:
          "Run and grow your business with SpotOn Terminal, the all-in-one smart device for payments, marketing, and customer insights.",
        link: "/products/terminal",
      },
    },
    {
      img: "foh.png",
      area: "e",

      popData: {
        name: "SpotOn Restaurant",
        description:
          "Online ordering, digital reservations & waitlisting, and elite reporting to keep your guests and employees happy—it’s all possible.",
        link: "/restaurant-pos",
      },
    },
    {
      img: "mobile.png",
      area: "f",
      popData: {
        name: "SpotOn Mobile",
        description:
          "The all-in-one smart device for payments, marketing, and customer insights.",
        link: "/products/mobile",
      },
    },
  ],
};

export const whyQuote = {
  quote:
    "SpotOn continues to be an invaluable technology partner that listens well and proactively presents  solutions to drive our business forward.",
  link: "",
  bg: "quote-why-hero.png",
  person: {
    name: "Kevin Youkilis",
    shop: "Loma Brewing",
    img: "kevin.png",
  },
};

export const whyBigReview = {
  linkWord: "Read all",
  target: "/",
  bigTitle: "What other business owners are saying",
  bigSub: "Read some of our 1500+ five star customer reviews.",
  reviews: [
    {
      quote:
        "From the initial consultation, to ordering, to implementation and integration, the entire process of on-boarding the SpotOn POS System has been seamless and has exceeded our expectations.",
      reviewDate: "Mar 9, 2021",
      reviewer: "Pete M.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn continues to be an invaluable technology partner that listens well and proactively presents innovative solutions to drive our business forward.",
      reviewDate: "Feb 9, 2021",
      reviewer: "Ben P.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn has given me the data access I need to manage my business from a macro as well as micro perspective. The software is easy to access and utilize.",
      reviewDate: "Dec 5, 2020",
      reviewer: "Pamela H.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "Every issue and question has been answered or addressed within hours, if not minutes.I can't say enough good about this company and software.",
      reviewDate: "Feb 24, 2021",
      reviewer: "Brandy R.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "From back office, digital marketing, built our website to take orders off of. The programming is great to allow you to do more tasks with better trained staff.",
      reviewDate: "Dec 1, 2020",
      reviewer: "Marcus O.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
  ],
};
export const whyWhiteGlove = {
  mainTitle: "The SpotOn way",
  title: "Technology with a human touch",
  content:
    "There are a lot of great products out there for you to choose from, but how many technology companies are willing to give you the level of personal support you deserve? At SpotOn, we’ll not only equip you with the best software, point-of-sale, and payment solutions, but we’ll also make sure you know how to get the most out of them, with in-person setup and service and 24/7 support included.\n",
  ctaInfoPrimary: {
    ctaTitle: "Learn more",
    ctaTarget: "/demo",
  },
};

export const whyLargeFeatures = {
  sectionTitle: "The loan you need. Your way.",
  featureBlocks: [
    {
      blockTitle: "How it works.",
      blockSubTitle:
        "SpotOn why is part of our end-to-end platform built to help your business compete and win in a fast changing world. When you use SpotOn for payments, our team will automatically notify you when you qualify for a small business loan.",
      blockImg: "why-a.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/demo",
      },
    },
    {
      blockTitle: "Get funds in 4 easy steps.",
      blockImg: "why-b.png",
      blockList: [
        "Log in to your SpotOn Dashboard to review your loan offer",
        "Choose your loan amount",
        "Complete the quick application and agree to the terms",
        "Get funds transferred directly into your account as soon as the next business day upon approval",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/demo",
      },
    },
    {
      blockTitle: "Easy repayment. No interest charges or hidden fees.",
      blockSubTitle:
        "SpotOn why makes paying off your loan stress-free by applying a fixed percentage of your daily sales directly toward your balance. In short, you pay more when sales are good, and pay less when sales are slow. Log in to your dashboard anytime to view your progress or make additional payments.",
      blockImg: "why-c.png",
      blockList: [
        "One fixed fee based on loan amount",
        "No personal guarantee required",
        "No periodic interest charges, monthly bills, or late fees",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/demo",
      },
    },
  ],
};

export const whyBusinessTypesCar = {
  title: "Serving all types of small businesses",
  slides: [
    {
      bg: "restaurant.png",
      cardTitle: "Restaurants",
      cardContent:
        "Fine Dining, Casual Dining, QSR, Food Stores, Food Trucks, and more",
      cardIcon: "cart.svg",
      icon: "restaurant.png",
      linkTo: "/restaurant-pos",
    },
    {
      bg: "auto.png",
      cardTitle: "Automotive",
      cardContent:
        "Oil Change Shops, Auto Parts, Auto Body, Repair, Tire Shops, Tint Shops, and more",
      icon: "automotive.png",
      linkTo: "/automotive",
    },
    {
      bg: "beauty.png",
      cardTitle: "Health & beauty",
      cardContent:
        "Nail/Hair Salons, Tanning Salons, Barbers, Dentists, Veterinarian, and more",
      icon: "health-and-beauty.png",
      linkTo: "/health-and-beauty",
    },
    {
      bg: "professional.png",
      cardTitle: "Professional Services",
      cardContent:
        "Lawyer, Accountant, Tow Truck, Architect, Plumber, Appraiser, and more",
      icon: "prof-services.png",
      linkTo: "/professional-services",
    },
  ],
};

export const whyVideo = {
  title: "See how it works",
  videoBtn: "Watch video",
  posterImg: "terminal-poster.png",
  videoUrlId: "wx4JxSMsC4s",
};
export const noVisual = {
  title: "Simplifying technology to tackle complex problems",
  subtext:
    "If you have software that you love, our team will work with you to integrate your SpotOn products. No forced fits here. We’re about helping you run your business your way.",
};

export const whySolutionsSection = {
  title: `Innovative technology to \n run & grow your business`,
  SecondaryCta: {
    ctaTitle: "View all",
    ctaTarget: "/page-2",
  },
};

export const whyTestimonials = {
  title: "What other business owners are saying",
  reviews: [
    {
      quote:
        "From the initial consultation, to ordering, to implementation and integration, the entire process of on-boarding the SpotOn POS System has been seamless and has exceeded our expectations.",
      reviewDate: "Mar 9, 2021",
      reviewer: "Pete M.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn continues to be an invaluable technology partner that listens well and proactively presents innovative solutions to drive our business forward.",
      reviewDate: "Feb 9, 2021",
      reviewer: "Ben P.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn has given me the data access I need to manage my business from a macro as well as micro perspective. The software is easy to access and utilize.",
      reviewDate: "Dec 5, 2020",
      reviewer: "Pamela H.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "Every issue and question has been answered or addressed within hours, if not minutes.I can't say enough good about this company and software.",
      reviewDate: "Feb 24, 2021",
      reviewer: "Brandy R.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "From back office, digital marketing, built our website to take orders off of. The programming is great to allow you to do more tasks with better trained staff.",
      reviewDate: "Dec 1, 2020",
      reviewer: "Marcus O.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
  ],
};

export const whyIntegrations = {
  title: "Integrations.",
  subtext:
    "Sync all kind of third party applications and service with SpotOn and automate your revenue growth, retention and marketing processes.",
  integrationsContent: [
    {
      title: "Compatible with SpotOn Register.",
      subtext:
        "Run and grow your business more efficiently than ever before with our cutting-edge point-of-sale solution.",
      contentImg: "register.png",
      ImgClassName: "register",
      btnInfo: {
        ctaTitle: "How it Works",
        ctaTarget: "/page-2",
      },
    },
    {
      title: "Out of the box Stripe integration.",
      subtext:
        "Accept whys and move money globally with Stripe’s powerful APIs and software solutions",
      contentImg: "phone.png",
      ImgClassName: "phone",
      btnInfo: {
        ctaTitle: "Read More",
        ctaTarget: "/page-2",
      },
    },
  ],
};

export const whyCTA = {
  title: "Book your consultation.",
  ctaImg: "cta-people.png",
  ctaInfo: {
    ctaTitle: "Learn more",
    ctaTarget: "/demo",
  },
};

export const whyFaqs = {
  title: "Frequently Asked Questions.",
  ctaInfo: {
    ctaTitle: "View more",
    ctaTarget: "/page-2",
  },
  list: [
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
  ],
};

export const whySolutionsSlides = [
  {
    title: "Appointments",
    img: "Appointments1.png",
    link: "/products/appointments",
  },
  {
    title: "Register",
    link: "/products/register",
    img: "Register.png",
  },
  {
    title: "Payments",
    link: "/products/payments",
    img: "Payments.png",
  },
  {
    title: "Mobile",
    link: "/products/mobile",
    img: "Mobile.png",
  },
  {
    title: "Virtual Terminal",
    link: "/products/virtual-terminal",
    img: "Virtual Terminal.png",
  },
  {
    title: "Online Ordering",
    link: "/products/online-order-and-delivery",
    img: "Online ordering.png",
  },
  {
    title: "Capital",
    link: "/products/capital",
    img: "Capital.png",
  },
  // {
  //   title: 'Payroll',
  //   link: '/products/payroll',
  //   img: 'Payroll.png',
  // },
  {
    title: "Delivery",
    link: "/products/delivery",
    img: "Delivery.png",
  },
  {
    title: "Loyalty",
    link: "/products/loyalty",
    img: "Loyalty.png",
  },
  {
    title: "Review Management",
    link: "/products/review-management",
    img: "Review management.png",
  },
  {
    title: "Marketing",
    link: "/products/marketing",
    img: "Marketing.png",
  },
  {
    title: "Reserve",
    link: "/products/reserve",
    img: "Reserve.png",
  },
  {
    title: "Website & E-commerce",
    link: "/products/e-commerce",
    img: "E-commerce & websites.png",
  },
  {
    title: "Terminal",
    link: "/products/terminal",
    img: "Terminal.png",
  },
  {
    title: "Reporting",
    link: "/products/reporting",
    img: "Reporting.png",
  },
  {
    title: "Restaurant",
    link: "/restaurant-pos",
    img: "Restaurant.png",
  },
];
export const whyUsOverview = {
  title: `SpotOn Promise`,
  subtext: `Whether you need simple mobile payment technology or a fully-integrated restaurant management system, we’ll work with you to customize the right set of tools for your business. And as those needs evolve, and your business grows, we not only have the existing technology to support you, but are constantly innovating to help you future proof your business. `,
  SecondaryCta: {
    ctaTitle: "Why SpotOn?",
    ctaTarget: "/company/why-spoton",
  },
};
